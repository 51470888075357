div.container {
  display: flex;
  margin-top: 30px;
}

div.productImage {
  min-width: 200px;
  margin-right: 30px;

  img {
    max-width: 200px;
  }
}

div.couponInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

span.info {
  margin-right: 30px;

  b {
    font-weight: bold;
  }

  i {
    font-style: italic;
  }
}

div.customerInfo {
  @extend div.couponInfo;

  padding-left: 30px;
  border-left: 1px solid black;
}

button.submit {
  margin-top: 20px;
  width: fit-content;
}
